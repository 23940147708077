




























































import { SfButton, SfIcon, SfLoader, SfSearchBar, SvgImage } from '~/components';
import {
  defineComponent, ref, watch, useRoute, computed
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { clickOutside } from '~/utilities/directives';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import { useFacet } from "~/modules/catalog/category/composables/useFacet";

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SfIcon,
    SvgImage,
    SfLoader
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    productsItemsPerPage: {
      type: Number,
      default: 12,
    },
    categoryItemsPerPage: {
      type: Number,
      default: 3,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  emits: ['set-is-open', 'set-search-results-products', 'set-search-results-categories', 'search-term'],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();
    const { search: productSearch, result: productResult, loading: productLoading } = useFacet();
    const { search: categorySearch, result: categoryResult, loading: categoryLoading } = useCategorySearch();
    const products = ref([]);
    const productsTotalCount = ref();

    const showSearch = function () {
        emit('set-is-open', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        emit('set-search-results-products', null);
        emit('set-search-results-categories', null);
        emit('search-term', null);
        emit('search-total-results', null);
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const resetSearch = (event) => {
      if (!!term.value) {
        term.value = '';
        emit('set-search-results-products', null);
        emit('set-search-results-categories', null);
        emit('search-term', null);
        emit('search-total-results', null);
      } else {
        closeSearch(event);
      }
    };

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      try {
        await productSearch({
          term: term.value,
          pageSize: props.productsItemsPerPage
        });
        products.value = facetGetters.getProducts(productResult.value) ?? [];
        productsTotalCount.value = productResult.value.data?.total;

        await categorySearch({
          filters: { name: { match: term.value } },
          customQuery: { pageSize: `${props.categoryItemsPerPage}` }
        });
        const categories = computed(() => categoryResult.value);

        emit('set-search-results-products', products!.value);
        emit('set-search-results-categories', categories!.value);
        emit('search-term', term.value );
        emit('search-total-results', productsTotalCount.value );
      } catch (error) {
        console.error('Error in SearchBar.vue:', error);
      }
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      rawHandleSearch(searchTerm);
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    return {
      loading:  productLoading || categoryLoading,
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      resetSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
      products,
    };
  },
});

